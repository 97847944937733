@font-face {
  font-family: "NanumGothic";
  font-weight: 700;
  src: url("./NanumGothic-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 400;
  src: url("./NotoSansKR-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 700;
  src: url("./NotoSansKR-Bold.otf") format("opentype");
}
